//import {ApolloProvider} from "@apollo/_client"
//import {useApollo} from "../apollo/client"
import Head from "next/head"
import {withTRPC} from "@trpc/next"
import "../styles/style.css"
//import "react-datepicker/dist/react-datepicker.css"
import {AppProps} from "next/app"

type MyAppProps = AppProps<{ initialApolloState: unknown }>

function MyApp(props: MyAppProps): JSX.Element {
  //const apolloClient = useApollo(props.pageProps.initialApolloState)

  return <>
    <Head>
        <meta name="facebook-domain-verification" content={process.env.NEXT_PUBLIC_META_DOMAIN_VERFICATION_ID}/>
        <script 
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`} 
          defer
        />
        <script 
          src="/analytics.js" 
          app-google-analytics-id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID} 
          defer
        /> 
        <script
            src="/meta-pixel.js"
            app-meta-pixel-id={process.env.NEXT_PUBLIC_META_PIXEL_ID}
            defer
        />
    </Head>
    {/*<ApolloProvider client={apolloClient}>*/}
    <props.Component {...props.pageProps} />
    {/*</ApolloProvider>*/}
  </>
}

export default withTRPC({
  config: () => ({url: "/api/trpc"}),
  ssr: false,
})(MyApp)
